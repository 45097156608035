import { IWebimUserData } from '../../types/consultantChat';
import { IApplicationState } from '../../types/redux';

export const getUserForChat = (state: IApplicationState): IWebimUserData | null => {
  const { user } = state;

  if (user.isAuthenticated && user.userId && user.kpnWebimChecksum) {
    const userData: IWebimUserData = {
      fields: {
        id: user.userId,
      },
      hash: user.kpnWebimChecksum,
    };

    if (user.displayName) {
      userData.fields.display_name = user.displayName;
    }

    return userData;
  }

  return null;
};
