import { Button } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './AuthorizationMotivationPopup.css';
import { ConsultantChatInfoPopup } from '../ConsultantChatInfoPopup';

interface IAuthorizationMotivationPopupProps {
  isOpen: boolean;
  onClose(): void;
  onClick(): void;
}

export const AuthorizationMotivationPopup = ({ onClick, onClose, isOpen }: IAuthorizationMotivationPopupProps) => {
  return (
    <ConsultantChatInfoPopup
      dataTestId="ChatAuthorizationMotivationPopup"
      title="Войдите, чтобы получить консультацию"
      isOpen={isOpen}
      onClose={onClose}
      image={<div className={style['image']} />}
      control={
        <Button data-testid="ChatAutorisationMotivationPopupSignIn" onClick={onClick} fullWidth>
          Войти
        </Button>
      }
    />
  );
};
