import { subscribeOnAuthentification } from './subscribeOnAuthentification';
import { IRequestAuthenticationParameters } from './types';
import { TThunkAction } from '../../types/redux';
import { callAuthenticationPopup } from '../../utils/authentication';

/* istanbul ignore next */
export function requestAuthentication(parameters?: IRequestAuthenticationParameters): TThunkAction {
  return dispatch => {
    if (process.env.IS_BROWSER_BUILD) {
      dispatch(subscribeOnAuthentification(parameters));

      callAuthenticationPopup({
        viewData: parameters?.viewData,
        source: parameters?.source,
      });
    }
  };
}
