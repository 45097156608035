import { IconButton, ModalFullscreen, Spinner } from '@cian/ui-kit';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './ConsultantCianChatPopup.css';

interface IConsultantCianChatPopupProps {
  url: string | null;
  onClose(): void;
}

// Не покрыто для наличия url, покроем после реализации ручки
/* istanbul ignore next */
export const ConsultantCianChatPopup: React.FC<IConsultantCianChatPopupProps> = ({ url, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const onLoad = () => setIsLoading(false);

  return (
    <ModalFullscreen open={true} onClose={onClose} theme="white">
      <div className={styles['container']} data-testid="ConsultantCianChatPopup">
        <span className={styles['close']}>
          <IconButton icon={<IconActionClose16 />} data-testid="BuilderChatCloseButton" onClick={onClose} />
        </span>
        {isLoading && (
          <span className={styles['spinner']} data-testid="spinner">
            <Spinner size={16} />
          </span>
        )}
        {url && <iframe className={styles['iframe']} src={url} onLoad={onLoad} onError={onLoad} />}
      </div>
    </ModalFullscreen>
  );
};
