import { OnboardingTooltip } from '@cian/ui-kit';
import * as React from 'react';

import { useChatOnboarding } from './hooks/useChatOnboarding';
import { ChatOnboardingContent } from '../../components/ChatOnboardingContent';
import { NBSP } from '../../constants';

export const ConsultantCianChatOnboarding = ({ children }: React.PropsWithChildren) => {
  const { open, closeHandler } = useChatOnboarding();

  if (!open) {
    return <>{children}</>;
  }

  return (
    <OnboardingTooltip
      theme="black"
      content={
        <ChatOnboardingContent>
          Консультант бесплатно подберёт вам квартиру в{NBSP}новостройке и{NBSP}ответит на вопросы
        </ChatOnboardingContent>
      }
      placement="top-start"
      open={true}
      onClose={closeHandler}
      flip={false}
      portal={false}
    >
      {children as React.ReactElement}
    </OnboardingTooltip>
  );
};
