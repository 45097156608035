import * as React from 'react';

import { ChatButton } from './components/ChatButton';
import { HandTooltip } from '../HandTooltip';

import * as styles from './ConsultantChat.css';

interface IConsultantChatProps {
  onClick?(event: React.MouseEvent): void;
  url?: string;
}

export function ConsultantChat({ onClick, url }: IConsultantChatProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['hand']}>
        <HandTooltip animated />
      </div>
      <ChatButton onClick={onClick} url={url} />
    </div>
  );
}
