import * as React from 'react';
import { useSelector } from 'react-redux';

import { ConsultantChat } from '../../components/ConsultantChat';
import { getIsEnabledConsultantChat, getConsultantChatLink } from '../../selectors/consultantChat';
import { ConsultantCianChatContainer } from '../ConsultantCianChat';
import { isEnabledSelector, isErrorSelector } from '../../selectors/consultantCianChat';

// eslint-disable-next-line import/no-default-export
export default function ConsultantChatContainer() {
  const chatLink = useSelector(getConsultantChatLink);
  const isEnabled = useSelector(getIsEnabledConsultantChat);
  const isEnabledV2 = useSelector(isEnabledSelector);
  const isError = useSelector(isErrorSelector);

  if (isEnabledV2 && !isError) {
    return <ConsultantCianChatContainer />;
  }

  if (!chatLink || !isEnabled || isError) {
    return null;
  }

  return <ConsultantChat url={chatLink} />;
}
