import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestAuthentication } from '../../../actions/authentication/requestAuthentication';
import {
  onConsultantCianCloseNoAuth,
  onConsultantCianSuccessAuth,
  onCianChatOpen,
} from '../../../actions/consultantCianChat';
import { userSelector } from '../../../selectors/user';
import { TThunkDispatch } from '../../../types/redux';

export function useConsultantCianChat() {
  const dispatch: TThunkDispatch = useDispatch();
  const { isAuthenticated } = useSelector(userSelector);

  const openChat = React.useCallback(() => {
    if (!isAuthenticated) {
      dispatch(
        requestAuthentication({
          onSuccess: onConsultantCianSuccessAuth(),
          onCloseNoAuth: onConsultantCianCloseNoAuth(),
        }),
      );

      return;
    }

    dispatch(onCianChatOpen());
  }, [dispatch, isAuthenticated]);

  return React.useMemo(
    () => ({
      openChat,
    }),
    [openChat],
  );
}
