import { IApplicationState } from '../../types/redux';

export const customLabelSelector = (state: IApplicationState): string => {
  const rscId = state.rcs.allIds[0];

  if (!rscId) {
    return '';
  }

  const rc = state.rcs.byId[rscId];

  return rc?.gaLabel || '';
};
