import * as React from 'react';
import classNames from 'clsx';

import * as styles from './HandTooltip.css';

interface ITooltipProps {
  animated?: boolean;
  onClick?(e: React.MouseEvent): void;
}

export function HandTooltip({ animated, onClick }: ITooltipProps) {
  const classes = classNames(styles['container'], animated && styles['animated']);

  return <div className={classes} onClick={onClick} />;
}
