import { IconButton, ModalFullscreen, UIHeading3 } from '@cian/ui-kit';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './ConsultantChatInfoPopup.css';

interface IConsultantChatInfoPopupProps {
  isOpen: boolean;
  onClose(): void;
  control: React.ReactNode;
  title: React.ReactNode;
  image?: React.ReactNode;
  dataTestId?: string;
}

export const ConsultantChatInfoPopup = ({
  onClose,
  isOpen,
  title,
  image,
  control,
  dataTestId,
}: IConsultantChatInfoPopupProps) => {
  return (
    <ModalFullscreen theme="white" open={isOpen} onClose={onClose}>
      <div data-testid={dataTestId} className={styles['container']}>
        <span className={styles['close']}>
          <IconButton icon={<IconActionClose16 />} data-testid="BuilderChatCloseButton" onClick={onClose} />
        </span>
        <div className={styles['content']}>
          {image}
          <UIHeading3 textAlign="center">{title}</UIHeading3>
        </div>
        <div className={styles['footer']}>{control}</div>
      </div>
    </ModalFullscreen>
  );
};
