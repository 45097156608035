import { IApplicationState } from '../../types/redux';
import { getUserForChat } from '../user';
import { getConsultantChatAccountName } from './getConsultantChatAccountName';

export const getConsultantChatLink = (state: IApplicationState): string | null => {
  const user = getUserForChat(state);
  const agent = getConsultantChatAccountName(state);

  if (!agent) {
    return null;
  }

  const url = new URL(`https://${agent}.webim.ru/client.php?visit-session-id=&location=AllClients&mode=chat`);

  if (user) {
    url.searchParams.append('provided-visitor', JSON.stringify(user));
  }

  return url.href;
};
