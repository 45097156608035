import * as React from 'react';

import * as styles from './ChatButton.css';

export interface IChatButtonProps {
  onClick?(e?: React.MouseEvent): void;
  url?: string;
}

export function ChatButton({ onClick, url }: IChatButtonProps) {
  return (
    <a
      data-testid="ChatButtonLink"
      className={styles['chat-btn']}
      href={url}
      onClick={onClick}
      target="_blank"
      rel="noopener noreferrer"
    />
  );
}
