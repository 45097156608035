import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isOpenSelector, isAuthorizationPopupOpenSelector } from '../../../selectors/consultantCianChat';
import { useApplicationContext } from '../../../utils/applicationContext';
import { getChatOnboardingCookie, setChatOnboardingCookie } from '../utils/chatOnboardingCookie';

interface IOnboardingTooltipResult {
  open: boolean;
  closeHandler: () => void;
}

const COOKIE_NAME = 'newbuilding-search-frontend.consultant_cian_chat_onboarding_shown';

export const useChatOnboarding = (): IOnboardingTooltipResult => {
  const { config } = useApplicationContext();

  const [open, setOpen] = useState(false);

  const isConsultantChatOpen = useSelector(isOpenSelector);
  const isConsultantAuthorizationPopupChatOpen = useSelector(isAuthorizationPopupOpenSelector);

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const isOnboardingEnabled = config.get<boolean>('Newbuilding.CianKpnChatOnboarding.Enabled') || false;

    if (isOnboardingEnabled && !getChatOnboardingCookie(COOKIE_NAME)) {
      setOpen(true);
      setChatOnboardingCookie(COOKIE_NAME);
    }
  }, [config]);

  useEffect(() => {
    if ((open && isConsultantChatOpen) || (open && isConsultantAuthorizationPopupChatOpen)) {
      closeHandler();
    }
  }, [closeHandler, isConsultantAuthorizationPopupChatOpen, isConsultantChatOpen, open]);

  return {
    open,
    closeHandler,
  };
};
