import * as React from 'react';

import * as styles from './ConsultantChatEntry.css';
import { ConsultantCianChatOnboarding } from '../../containers/ConsultantCianChatOnboarding';
import { Spinner } from '@cian/ui-kit';
import cx from 'clsx';

interface IConsultantChatEntryProps {
  isLoading: boolean;
  onClick: () => void;
}

export const ConsultantChatEntry: React.FC<IConsultantChatEntryProps> = ({ onClick, isLoading }) => {
  return (
    <div className={styles['container']}>
      <ConsultantCianChatOnboarding>
        <div className={styles['onboarding']} />
      </ConsultantCianChatOnboarding>
      <div
        className={cx(styles['chat-control'], isLoading && styles['chat-control__loading'])}
        onClick={onClick}
        data-testid="ConsultantChatButton"
      >
        {isLoading ? <Spinner size={20} color="white_100" /> : <div className={styles['chat-logo']} />}
      </div>
    </div>
  );
};
